import { render, staticRenderFns } from "./dao-to-join-list.vue?vue&type=template&id=74e126f7&scoped=true&"
import script from "./dao-to-join-list.vue?vue&type=script&lang=ts&"
export * from "./dao-to-join-list.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e126f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VProgressCircular,VSkeletonLoader})
