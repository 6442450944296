
















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { DaoToJoinViewModel } from '../../viewmodels/dao-to-join-view-model'

@Observer
@Component({
  components: {
    'dao-to-join-item': () => import('./dao-to-join-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class DaoToJoinList extends Vue {
  @Inject({}) vm!: DaoToJoinViewModel

  loadMore(entry) {
    if (this.vm.canLoadMore) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }
}
